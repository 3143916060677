import gql from 'graphql-tag'

const GET_USERS = gql`
    query {
        users {
            _id
            creation
            email
            name
            username
            role
        }
    }
`

const GET_USER = gql`
    query user($_id: String!) {
        user(_id: $_id) {
            _id
            creation
            email
            name
            username
            role
            trackers {
                tracker_id
                tracker_url
                tracker_type
                tracker_name
                tracker_key
                tracker_username
            }
        }
    }
`

const ADD_USER = gql`
    mutation AddUser(
        $name: String!
        $username: String!
        $email: String!
        $role: String!
    ) {
        add_user(name: $name, username: $username, email: $email, role: $role) {
            _id
            creation
            email
            name
            username
            role
        }
    }
`

const UPDATE_USER = gql`
    mutation update_user(
        $_id: String!
        $name: String!
        $username: String!
        $email: String!
        $role: String
    ) {
        update_user(
            _id: $_id
            name: $name
            username: $username
            email: $email
            role: $role
        ) {
            _id
            creation
            email
            name
            username
            role
        }
    }
`

const REMOVE_USER = gql`
    mutation RemoveUser($_id: String!) {
        remove_user(_id: $_id) {
            _id
            creation
            email
            name
            username
            role
        }
    }
`

const UPDATE_TRACKER_KEY = gql`
    mutation UpdateTrackerKey(
        $_id: String!
        $tracker_id: String!
        $tracker_key: String
        $tracker_username: String
    ) {
        update_tracker_key(
            _id: $_id
            tracker_id: $tracker_id
            tracker_key: $tracker_key
            tracker_username: $tracker_username
        ) {
            _id
            creation
            email
            name
            username
            role
            trackers {
                tracker_id
                tracker_url
                tracker_type
                tracker_name
                tracker_key
                tracker_username
            }
        }
    }
`

const CHANGE_PASSWORD = gql`
    mutation changePassword(
        $_id: String
        $old_password: String
        $new_password: String
    ) {
        change_password(
            _id: $_id
            old_password: $old_password
            new_password: $new_password
        ) {
            updated
        }
    }
`

export {
    GET_USERS,
    ADD_USER,
    UPDATE_USER,
    REMOVE_USER,
    GET_USER,
    UPDATE_TRACKER_KEY,
    CHANGE_PASSWORD,
}
