import React, { Component } from 'react'

class Sidebar extends Component {
    render() {
        return (
            <div className="sidebar">
                <div className="sidebar-sticky">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <a className="nav-link active" href="#">
                                <i className="fas fa-info-circle nav-link-icon" />
                                <span className="nav-link-name">Details</span>{' '}
                                <span className="sr-only">(current)</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                <i className="fas fa-tasks nav-link-icon" />
                                <span className="nav-link-name">
                                    Test cases
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                <i className="fas fa-th-large nav-link-icon" />
                                <span className="nav-link-name">
                                    Test suites
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                <i className="fas fa-tachometer-alt nav-link-icon" />
                                <span className="nav-link-name">Runs</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Sidebar
