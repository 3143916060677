import React, { Component } from 'react'
import PageTitle from '../components/menu/user-menu/general/PageTitle'
import TrackerTable from '../components/menu/user-menu/trackers/TrackerTable'

import { Query } from 'react-apollo'
import { GET_TRACKERS } from './queries/TrackerQueries'
import Notifier from '../components/project-pages/common/Notifier'

const initialState = {
    createNewTracker: false,
    editTracker: -1,
    deleteTracker: -1,
    notifierTitle: '',
    notifierMsg: '',
}

class Trackers extends Component {
    constructor(props) {
        super(props)

        this.state = { ...initialState }
        this.showCreation = this.showCreation.bind(this)
        this.handleEditTracker = this.handleEditTracker.bind(this)
        this.handleCancelEditing = this.handleCancelEditing.bind(this)
        this.renderWarning = this.renderWarning.bind(this)
        this.handleCancelDeleting = this.handleCancelDeleting.bind(this)
        this.cancelCreation = this.cancelCreation.bind(this)
        this.activeNotifier = this.activeNotifier.bind(this)
    }

    componentDidUpdate(_, prevState) {
        const {
            notifierTitle: currentNotifierTitle,
            notifierMsg: currentNotifierMsg,
        } = this.state
        const {
            notifierTitle: previousNotifierTitle,
            notifierMsg: previousNotifierMsg,
        } = prevState
        if (
            currentNotifierTitle &&
            currentNotifierMsg &&
            currentNotifierTitle !== previousNotifierTitle &&
            currentNotifierMsg !== previousNotifierMsg
        ) {
            this.resetState()
        }
    }

    resetState() {
        this.setState({ ...initialState })
    }

    handleCancelEditing() {
        this.resetState()
    }

    handleEditTracker(evt, index) {
        evt.preventDefault()
        this.setState({
            ...initialState,
            editTracker: index,
        })
    }

    showCreation() {
        this.setState({
            ...initialState,
            createNewTracker: true,
        })
    }

    cancelCreation() {
        this.resetState()
    }

    handleCancelDeleting() {
        this.resetState()
    }

    renderWarning(evt, index) {
        evt.preventDefault()
        this.setState({
            ...initialState,
            deleteTracker: index,
        })
    }
    activeNotifier(response, message) {
        this.setState({
            ...initialState,
            notifierTitle: response,
            notifierMsg: message,
        })
    }

    render() {
        const tableHandlers = {
                handleEditTracker: this.handleEditTracker,
                handleCancelEditing: this.handleCancelEditing,
                handleDeleteNotification: this.renderWarning,
                handleCancelDeleting: this.handleCancelDeleting,
                cancelCreation: this.cancelCreation,
                activeNotifier: this.activeNotifier,
            },
            pageTitle = {
                title: {
                    title: 'Trackers management',
                    icon: 'fas fa-file-alt head-page__title-ico',
                },
                buttonProperties: {
                    id: 'create-new-tracker',
                    title: 'New Tracker',
                    icon: 'fas fa-plus-circle',
                    class: 'btn btn-success',
                    handler: this.showCreation,
                },
            }

        return (
            <Query query={GET_TRACKERS} notifyOnNetworkStatusChange>
                {({ loading, error, data, networkStatus }) => {
                    if (networkStatus === 4) return <p>Refetch</p>
                    if (loading) return <p>Loading...</p>
                    if (error) return <p>Error :(</p>
                    if (data == null || data.trackers === null)
                        return <p>No results</p>

                    return (
                        <div className="l__full-page">
                            <div className="container-fluid">
                                <Notifier
                                    title={this.state.notifierTitle}
                                    message={this.state.notifierMsg}
                                    type={this.state.notifierTitle}
                                />
                                <PageTitle
                                    title={pageTitle.title}
                                    buttonProperties={
                                        pageTitle.buttonProperties
                                    }
                                />
                                <TrackerTable
                                    handlers={tableHandlers}
                                    trackers={data.trackers}
                                    deleteTracker={this.state.deleteTracker}
                                    editTracker={this.state.editTracker}
                                    newTracker={this.state.createNewTracker}
                                />
                            </div>
                        </div>
                    )
                }}
            </Query>
        )
    }
}

export default Trackers
