import React, { Component } from 'react'
import 'react-notifications/lib/notifications.css'
import PropTypes from 'prop-types'

import { NotificationContainer, NotificationManager } from 'react-notifications'
class Notifier extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.createNotification = this.createNotification.bind(this)
    }

    createNotification() {
        switch (this.props.type) {
            case 'info':
                return NotificationManager.info(this.props.message)
            case 'success':
                return NotificationManager.success(
                    this.props.message,
                    this.props.title,
                    4000
                )
            case 'warning':
                return NotificationManager.warning(
                    this.props.message,
                    this.props.title,
                    4000
                )
            case 'error':
                return NotificationManager.error(
                    this.props.message,
                    this.props.title,
                    4000
                )
            default:
                break
        }
    }

    render() {
        return (
            <div>
                {this.createNotification()}
                <NotificationContainer />
            </div>
        )
    }
}
Notifier.propTypes = {
    response: PropTypes.any,
    type: PropTypes.string,
    message: PropTypes.string,
    title: PropTypes.string,
    deactiveNotifier: PropTypes.func,
}
export default Notifier
