import React from 'react'
import PropTypes from 'prop-types'

const InputField = props => {
    return (
        <div className="form-group">
            <input
                type={props.type}
                className="form-control"
                id={props.id}
                aria-describedby=""
                placeholder={props.placeholder}
                defaultValue={props.defaultValue}
                onChange={
                    props.onChange != undefined
                        ? e => props.onChange(props.id, e.target.value)
                        : null
                }
                onBlur={
                    props.onBlur != undefined
                        ? e => props.onBlur(props.id, e.target.value)
                        : null
                }
            />
        </div>
    )
}
InputField.propTypes = {
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    defaultValue: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    type: PropTypes.string,
}
export default InputField
