import React from 'react'
import PropTypes from 'prop-types'
import EditTracker from './EditTracker.js'
import { Mutation } from 'react-apollo'
import {
    GET_TRACKERS,
    REMOVE_TRACKER,
} from '../../../../containers/queries/TrackerQueries'
import { GET_USER } from '../../../../containers/queries/UsersQueries'
import Buttons from '../general/Buttons'

const DeleteTracker = (_id, index, name, url, type, agent) => {
    return (
        <tr className="table-danger">
            <th scope="row" id={`name-${index}`}>
                <i className="table-ico-prefix fas fa-file-signature" /> {name}
            </th>
            <td id={`url-${index}`}>
                <i className="table-ico-prefix fas fa-link" /> {url}
            </td>
            <td id={`url-${type}`}>
                <i className="table-ico-prefix fas fa-signature" /> {type}
            </td>
            <td className="text-right">
                <Mutation
                    mutation={REMOVE_TRACKER}
                    refetchQueries={() => {
                        return [
                            { query: GET_TRACKERS },
                            {
                                query: GET_USER,
                                variables: { _id: localStorage.id },
                            },
                        ]
                    }}
                    onCompleted={() => {
                        agent.activeNotifier(
                            'success',
                            'Tracker is removed successfully.'
                        )
                    }}
                    onError={() => {
                        agent.activeNotifier(
                            'error',
                            'Failure in removing the tracker.'
                        )
                    }}
                >
                    {removeTracker => {
                        const handlers = {
                            submit: () => {
                                removeTracker({
                                    variables: {
                                        _id: _id,
                                    },
                                })
                            },
                            cancel: agent.handleCancelDeleting,
                        }
                        const submitButton = {
                            id: 'submit-delete',
                            buttonClass: 'btn btn-danger mr-5 btn-small',
                            icon: 'fas fa-check-circle',
                            textClass: 'd-none d-lg-inline ml-5',
                            title: 'Delete',
                        }
                        return (
                            <Buttons
                                submitButton={submitButton}
                                handlers={handlers}
                            />
                        )
                    }}
                </Mutation>
            </td>
        </tr>
    )
}

const EnableEdit = (_id, index, name, url, type, endPoints, handlers) => {
    return (
        <EditTracker
            _id={_id}
            handlers={handlers}
            index={index}
            name={name}
            url={url}
            type={type}
            endPoints={endPoints}
        />
    )
}

const Tracker = (_id, index, name, url, type, handlers) => {
    return (
        <tr key={_id}>
            <th scope="row" id={`name-${index}`}>
                <i className="table-ico-prefix fas fa-file-signature" /> {name}
            </th>
            <td id={`url-${index}`}>
                <i className="table-ico-prefix fas fa-link" /> {url}
            </td>
            <td id={`url-${type}`}>
                <i className="table-ico-prefix fas fa-signature" /> {type}
            </td>
            <td className="text-right">
                <a
                    href=""
                    id={`edit-${index}`}
                    onClick={e => handlers.handleEditTracker(e, index)}
                    className="text-info mr-10"
                >
                    <i className="fas fa-edit hover-pulse" />
                </a>
                <a
                    href=""
                    id={`delete-${index}`}
                    className="text-danger"
                    onClick={e => handlers.handleDeleteNotification(e, index)}
                >
                    <i className="fas fa-times-circle hover-pulse" />
                </a>
            </td>
        </tr>
    )
}

const RenderTracker = props => {
    const {
        _id,
        index,
        name,
        url,
        type,
        deleteTracker,
        enableEdit,
        endPoints,
        handlers,
    } = props
    if (deleteTracker) {
        return DeleteTracker(_id, index, name, url, type, handlers)
    } else if (enableEdit) {
        return EnableEdit(_id, index, name, url, type, endPoints, handlers)
    } else {
        return Tracker(_id, index, name, url, type, handlers)
    }
}
RenderTracker.propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    _id: PropTypes.string.isRequired,
    deleteTracker: PropTypes.bool.isRequired,
    handlers: PropTypes.object.isRequired,
}
export default RenderTracker
