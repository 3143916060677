import React from 'react'
import PropTypes from 'prop-types'
import EditUser from './EditUser.js'
import { Mutation } from 'react-apollo'
import {
    GET_USERS,
    REMOVE_USER,
} from '../../../../containers/queries/UsersQueries'
import Buttons from '../general/Buttons'
import { dateParser } from '../../../project-pages/common/Functions'

const DeleteUser = (
    _id,
    index,
    creationDate,
    name,
    username,
    email,
    role,
    agent
) => {
    return (
        <tr className="table-danger">
            <td>{dateParser(creationDate)}</td>
            <td id={`name-${index}`}>
                <i className="table-ico-prefix fas fa-file-signature" /> {name}
            </td>
            <td id={`username-${index}`}>
                <i className="table-ico-prefix fas fa-link" /> {username}
            </td>
            <td id={`email-${email}`}>
                <i className="table-ico-prefix fas fa-signature" /> {email}
            </td>
            <td />
            <td />
            <td className="text-right">
                <Mutation
                    mutation={REMOVE_USER}
                    refetchQueries={() => {
                        return [{ query: GET_USERS }]
                    }}
                    onCompleted={() => {
                        agent.activeNotifier(
                            'success',
                            'User is deleted successfully.'
                        )
                    }}
                    onError={() => {
                        agent.activeNotifier('error', 'Failure in deletion.')
                    }}
                >
                    {removeUser => {
                        const handlers = {
                            submit: () => {
                                removeUser({
                                    variables: {
                                        _id: _id,
                                    },
                                })
                            },
                            cancel: agent.handleCancelDeleting,
                        }
                        const submitButton = {
                            id: 'submit-delete',
                            buttonClass: 'btn btn-danger mr-5 btn-small',
                            icon: 'fas fa-check-circle',
                            textClass: 'd-none d-lg-inline ml-5',
                            title: 'Delete',
                        }
                        return (
                            <Buttons
                                submitButton={submitButton}
                                handlers={handlers}
                            />
                        )
                    }}
                </Mutation>
            </td>
        </tr>
    )
}

const EnableEdit = (
    _id,
    index,
    creationDate,
    name,
    username,
    email,
    role,
    handlers
) => {
    return (
        <EditUser
            _id={_id}
            handlers={handlers}
            index={index}
            name={name}
            creationDate={creationDate}
            username={username}
            email={email}
            role={role}
        />
    )
}

const User = (
    _id,
    index,
    creationDate,
    name,
    username,
    email,
    role,
    handlers
) => {
    return (
        <tr key={_id}>
            <td scope="row" id={`name-${index}`}>
                <i className="table-ico-prefix fas fa-calendar-day" />{' '}
                {dateParser(creationDate)}
            </td>
            <td>
                <i className="table-ico-prefix fas fa-user" /> {name}
            </td>
            <td>
                <i className="table-ico-prefix fas fa-tag" /> {username}
            </td>
            <td>
                <i className="table-ico-prefix fas fa-envelope" /> {email}
            </td>
            <td>
                <i className="fas fa-lock" />
            </td>
            <td>
                <i
                    className={
                        role == 'admin'
                            ? 'text-warning fas fa-star'
                            : 'text-warning'
                    }
                />
            </td>
            <td className="text-right">
                <a
                    className="text-info mr-10"
                    id={`edit-${index}`}
                    onClick={e => handlers.handleEditUser(e, index)}
                >
                    <i className="fas fa-edit hover-pulse" />
                </a>
                <a
                    id={`delete-${index}`}
                    className="text-danger"
                    onClick={e => handlers.handleDeleteNotification(e, index)}
                >
                    <i className="fas fa-times-circle hover-pulse" />
                </a>
            </td>
        </tr>
    )
}

const RenderUser = props => {
    const {
        _id,
        creationDate,
        index,
        name,
        username,
        email,
        role,
        deleteUser,
        enableEdit,
        handlers,
    } = props
    if (deleteUser) {
        return DeleteUser(
            _id,
            index,
            creationDate,
            name,
            username,
            email,
            role,
            handlers
        )
    } else if (enableEdit) {
        return EnableEdit(
            _id,
            index,
            creationDate,
            name,
            username,
            email,
            role,
            handlers
        )
    } else {
        return User(
            _id,
            index,
            creationDate,
            name,
            username,
            email,
            role,
            handlers
        )
    }
}
RenderUser.propTypes = {
    _id: PropTypes.string.isRequired,
    creationDate: PropTypes.string,
    index: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    role: PropTypes.string,
    deleteUser: PropTypes.bool.isRequired,
    enableEdit: PropTypes.bool.isRequired,
    handlers: PropTypes.object.isRequired,
}
export default RenderUser
