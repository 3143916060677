import React, { Component } from 'react'
import PageTitle from '../components/menu/user-menu/general/PageTitle'
import UsersTable from '../components/menu/user-menu/users/UsersTable'

import { Query } from 'react-apollo'
import { GET_USERS } from './queries/UsersQueries'
import Notifier from '../components/project-pages/common/Notifier'

const initialState = {
    createNewUser: false,
    editUser: -1,
    deleteUser: -1,
    notifierTitle: '',
    notifierMsg: '',
}

class UsersManagemnet extends Component {
    constructor(props) {
        super(props)

        this.state = { ...initialState }
        this.showCreation = this.showCreation.bind(this)
        this.handleEditUser = this.handleEditUser.bind(this)
        this.handleCancelEditing = this.handleCancelEditing.bind(this)
        this.renderWarning = this.renderWarning.bind(this)
        this.handleCancelDeleting = this.handleCancelDeleting.bind(this)
        this.cancelCreation = this.cancelCreation.bind(this)
        this.activeNotifier = this.activeNotifier.bind(this)
    }

    componentDidUpdate(_, prevState) {
        const {
            notifierTitle: currentNotifierTitle,
            notifierMsg: currentNotifierMsg,
        } = this.state
        const {
            notifierTitle: previousNotifierTitle,
            notifierMsg: previousNotifierMsg,
        } = prevState
        if (
            currentNotifierTitle &&
            currentNotifierMsg &&
            currentNotifierTitle !== previousNotifierTitle &&
            currentNotifierMsg !== previousNotifierMsg
        ) {
            this.resetState()
        }
    }

    resetState() {
        this.setState({ ...initialState })
    }

    handleCancelEditing() {
        this.resetState()
    }

    handleEditUser(evt, index) {
        evt.preventDefault()
        this.setState({ ...initialState, editUser: index })
    }

    showCreation() {
        this.setState({
            ...initialState,
            createNewUser: true,
        })
    }
    cancelCreation() {
        this.resetState()
    }

    handleCancelDeleting() {
        this.resetState()
    }

    renderWarning(evt, index) {
        evt.preventDefault()
        this.setState({ ...initialState, deleteUser: index })
    }
    activeNotifier(response, message) {
        this.setState({
            ...initialState,
            notifierTitle: response,
            notifierMsg: message,
        })
    }
    render() {
        const tableHandlers = {
                handleEditUser: this.handleEditUser,
                handleCancelEditing: this.handleCancelEditing,
                handleDeleteNotification: this.renderWarning,
                handleCancelDeleting: this.handleCancelDeleting,
                cancelCreation: this.cancelCreation,
                activeNotifier: this.activeNotifier,
            },
            pageTitle = {
                title: {
                    title: 'Users management',
                    icon: 'fas fa-users-cog head-page__title-ico',
                },
                buttonProperties: {
                    id: 'create-new-user',
                    title: 'New User',
                    icon: 'fas fa-user-shield',
                    class: 'btn btn-success',
                    handler: this.showCreation,
                },
            }
        return (
            <Query query={GET_USERS} notifyOnNetworkStatusChange>
                {({ loading, error, data, networkStatus }) => {
                    if (networkStatus === 4) return <p>Refetch</p>
                    if (loading) return <p>Loading...</p>
                    if (error) return <p>Error :(</p>
                    if (data == null || data.users === null)
                        return <p>No results</p>

                    return (
                        <div className="l__full-page">
                            <div className="container-fluid">
                                <Notifier
                                    title={this.state.notifierTitle}
                                    message={this.state.notifierMsg}
                                    type={this.state.notifierTitle}
                                />
                                <PageTitle
                                    title={pageTitle.title}
                                    buttonProperties={
                                        pageTitle.buttonProperties
                                    }
                                />
                                <UsersTable
                                    handlers={tableHandlers}
                                    users={data.users}
                                    deleteUser={this.state.deleteUser}
                                    editUser={this.state.editUser}
                                    newUser={this.state.createNewUser}
                                />
                            </div>
                        </div>
                    )
                }}
            </Query>
        )
    }
}

export default UsersManagemnet
