import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import PropTypes from 'prop-types'
import Buttons from '../general/Buttons'

import {
    GET_TRACKERS,
    ADD_TRACKER,
} from '../../../../containers/queries/TrackerQueries'
import { GET_USER } from '../../../../containers/queries/UsersQueries'
class NewTracker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tracker: {
                name: '',
                url: '',
                type: 'Redmine',
            },
            types: ['Redmine', 'Jira'],
        }
        this.handleTrackerChange = this.handleTrackerChange.bind(this)
        this.handleTypeChange = this.handleTypeChange.bind(this)
    }
    checkValidity() {
        var tracker = this.state.tracker.url
        if (tracker[tracker.length - 1] !== '/') {
            tracker = tracker + '/'
            this.setState({ tracker: tracker })
            return tracker
        } else return tracker
    }

    handleTrackerChange() {
        let tracker = this.state.tracker
        tracker[event.target.id] = event.target.value
        this.setState(tracker)
    }

    handleTypeChange(event) {
        let tracker = this.state.tracker
        tracker.type = event.target.value
        this.setState({ tracker: tracker })
    }

    render() {
        return (
            <tr className="table-success">
                <th scope="row">
                    <input
                        placeholder="Name"
                        id="name"
                        defaultValue=""
                        onChange={this.handleTrackerChange}
                    />
                </th>
                <td>
                    <input
                        placeholder="url"
                        id="url"
                        defaultValue=""
                        onChange={this.handleTrackerChange}
                    />
                </td>
                <td>
                    <div className="form-group">
                        <select
                            className="form-control"
                            id="endPoints"
                            onChange={this.handleTypeChange}
                        >
                            {this.state.types.map(type => (
                                <option id={type} key={type}>
                                    {type}
                                </option>
                            ))}
                        </select>
                    </div>
                </td>
                <td className="text-right">
                    <Mutation
                        mutation={ADD_TRACKER}
                        refetchQueries={() => {
                            return [
                                { query: GET_TRACKERS },
                                {
                                    query: GET_USER,
                                    variables: { _id: localStorage.id },
                                },
                            ]
                        }}
                        onCompleted={() => {
                            this.props.handlers.activeNotifier(
                                'success',
                                'Tracker is added successfully.'
                            )
                        }}
                        onError={() => {
                            this.props.handlers.activeNotifier(
                                'error',
                                'Failure in adding new tracker.'
                            )
                        }}
                    >
                        {addTracker => {
                            const handlers = {
                                submit: () => {
                                    if (
                                        this.state.tracker.name !== '' &&
                                        this.state.tracker.url !== ''
                                    ) {
                                        addTracker({
                                            variables: {
                                                url: this.checkValidity(),
                                                name: this.state.tracker.name,
                                                type: this.state.tracker.type,
                                            },
                                        })
                                    }
                                },
                                cancel: this.props.handlers.cancelCreation,
                            }
                            const submitButton = {
                                id: 'create_tracker',
                                buttonClass:
                                    this.state.tracker.name !== '' &&
                                    this.state.tracker.url !== ''
                                        ? 'btn btn-success mr-5 btn-small'
                                        : 'btn btn-success is-disabled mr-5 btn-small',
                                icon: 'fas fa-check-circle',
                                textClass: 'd-none d-lg-inline ml-5',
                                title: 'Create',
                            }
                            return (
                                <Buttons
                                    submitButton={submitButton}
                                    handlers={handlers}
                                />
                            )
                        }}
                    </Mutation>
                </td>
            </tr>
        )
    }
}
NewTracker.propTypes = {
    type: PropTypes.array,
    handlers: PropTypes.object.isRequired,
}
export default NewTracker
