import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Buttons from '../general/Buttons'
import { dateParser } from '../../../project-pages/common/Functions'

import { Mutation } from 'react-apollo'
import {
    GET_USERS,
    UPDATE_USER,
} from '../../../../containers/queries/UsersQueries'

class EditUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: this.props.name,
            username: this.props.username,
            email: this.props.email,
            role: this.props.role,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this)
    }

    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value })
    }
    handleChangeCheckBox(event) {
        this.setState({ role: event.target.checked ? 'admin' : 'user' })
    }

    render() {
        return (
            <tr className="table-info">
                <td>{dateParser(this.props.creationDate)}</td>
                <td>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Name"
                            defaultValue={this.props.name}
                            id="name"
                            onChange={this.handleChange}
                        />
                    </div>
                </td>
                <td>
                    <div className="form-group">
                        {this.props._id === localStorage.id ? (
                            <label>
                                {' '}
                                <i className="table-ico-prefix fas fa-user" />{' '}
                                {this.props.username}{' '}
                            </label>
                        ) : (
                            <input
                                type="text"
                                placeholder="Username"
                                defaultValue={this.props.username}
                                id="username"
                                onChange={this.handleChange}
                            />
                        )}
                    </div>
                </td>
                <td>
                    <div className="form-group">
                        {this.props._id === localStorage.id ? (
                            <label>
                                {' '}
                                <i className="table-ico-prefix fas fa-envelope" />{' '}
                                {this.props.email}{' '}
                            </label>
                        ) : (
                            <input
                                type="text"
                                placeholder="Email"
                                defaultValue={this.props.email}
                                id="email"
                                onChange={this.handleChange}
                            />
                        )}
                    </div>
                </td>
                <td />
                <td>
                    {this.props._id === localStorage.id ? (
                        <label>
                            {' '}
                            <i className="text-warning fas fa-star" /> Admin{' '}
                        </label>
                    ) : (
                        <div className="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                onChange={this.handleChangeCheckBox}
                                checked={
                                    this.state.role === 'admin' ? true : false
                                }
                                className="custom-control-input"
                                id="customCheck2"
                            />
                            <label
                                className="custom-control-label"
                                htmlFor="customCheck2"
                            >
                                Admin
                            </label>
                        </div>
                    )}
                </td>
                <td className="text-right">
                    <Mutation
                        mutation={UPDATE_USER}
                        refetchQueries={() => {
                            return [{ query: GET_USERS }]
                        }}
                        onCompleted={() => {
                            this.props.handlers.activeNotifier(
                                'success',
                                'User is updated successfully.'
                            )
                        }}
                        onError={() => {
                            this.props.handlers.activeNotifier(
                                'error',
                                'Failure in updating the user.'
                            )
                        }}
                    >
                        {updateUser => {
                            const handlers = {
                                submit: () => {
                                    updateUser({
                                        variables: {
                                            _id: this.props._id,
                                            name: this.state.name,
                                            username: this.state.username,
                                            email: this.state.email,
                                            role: this.state.role,
                                        },
                                    })
                                },
                                cancel: this.props.handlers.handleCancelEditing,
                            }
                            const submitButton = {
                                id: 'save_user',
                                buttonClass:
                                    this.state.name !== '' &&
                                    this.state.username !== '' &&
                                    this.state.email !== ''
                                        ? 'btn btn-success mr-5 btn-small'
                                        : 'btn btn-success is-disabled mr-5 btn-small',
                                icon: 'fas fa-check-circle',
                                textClass: 'd-none d-lg-inline ml-5',
                                title: 'Save',
                            }
                            return (
                                <Buttons
                                    submitButton={submitButton}
                                    handlers={handlers}
                                />
                            )
                        }}
                    </Mutation>
                </td>
            </tr>
        )
    }
}
EditUser.propTypes = {
    _id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    creationDate: PropTypes.string,
    role: PropTypes.string,
    handlers: PropTypes.object.isRequired,
}
export default EditUser
