import React, { Component } from 'react'
import logo from '../assets/img/logo.svg'
import HelpMenu from '../components/menu/HelpMenu.js'
import UserMenu from '../components/menu/UserMenu.js'
import { Query } from 'react-apollo'
import { GET_USER } from './queries/UsersQueries'
import { GetDirectory } from '../components/project-pages/common/Functions'

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            updatestate: false,
        }
        this.updateDirectory = this.updateDirectory.bind(this)
    }

    updateDirectory() {
        this.setState({ updatestate: true })
    }

    render() {
        return (
            <header className="main fixed-top flex-md-nowrap">
                <nav className="navbar navbar-expand-lg navbar-dark main__navbar">
                    <div>
                        <a className="navbar-brand" href="/">
                            <img src={logo} alt="" />
                        </a>
                    </div>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon" />
                    </button>

                    <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                    >
                        <Query
                            query={GET_USER}
                            variables={{ _id: localStorage.id }}
                            notifyOnNetworkStatusChange
                        >
                            {({ loading, data = { user: {} } }) => {
                                if (loading) return <p>Loading...</p>
                                return (
                                    <ul className="navbar-nav ml-auto">
                                        <li className="nav-item dropdown">
                                            <a
                                                className="nav-link dropdown-toggle"
                                                href="#"
                                                id="navbarDropdown-help"
                                                role="button"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <i className="dropdown-icon fas fa-question-circle" />{' '}
                                                Help
                                            </a>
                                            <HelpMenu user={data.user} />
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a
                                                className="nav-link dropdown-toggle"
                                                href="#"
                                                id="navbarDropdown-admin"
                                                role="button"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <i className="dropdown-icon fas fa-user" />{' '}
                                                Hello, {data.user.username}
                                            </a>
                                            <UserMenu
                                                user={data.user}
                                                handler={this.updateDirectory}
                                            />
                                        </li>
                                    </ul>
                                )
                            }}
                        </Query>
                    </div>
                </nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item" aria-current="page">
                        {GetDirectory()}
                    </li>
                </ol>
            </header>
        )
    }
}
export default Header
