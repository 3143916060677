import React from 'react'
import PropTypes from 'prop-types'
const PageTitle = props => {
    return (
        <div id="head-page" className="row">
            <div className="col-sm-6 head-page__title">
                <h1 className="head-page__title-title">
                    <i className={props.title.icon} /> {props.title.title}
                </h1>
            </div>
            <div className="col-sm-6 head-page__option text-sm-right">
                <button
                    id={props.buttonProperties.id}
                    className={props.buttonProperties.class}
                    onClick={() => props.buttonProperties.handler()}
                >
                    <i className={props.buttonProperties.icon} />{' '}
                    {props.buttonProperties.title}
                </button>
            </div>
        </div>
    )
}
PageTitle.propTypes = {
    title: PropTypes.object.isRequired,
    buttonProperties: PropTypes.object.isRequired,
}
export default PageTitle
