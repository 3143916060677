import React from 'react'

const HelpMenu = () => {
    return (
        <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdown-help"
        >
            <a
                className="dropdown-item"
                href=""
                onClick={e => (
                    e.preventDefault(),
                    window.open('/#/gherkinReference', '_blank')
                )}
            >
                <i className="dropdown-icon fas fa-asterisk" /> Gherkin
                Reference
            </a>
        </div>
    )
}
export default HelpMenu
