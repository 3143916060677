import React, { Component } from 'react'
import { Route } from 'react-router-dom'

import Trackers from './Trackers'
import Profile from './Profile'
import UsersManagemnet from './UsersManagemnet'
import PageWithSidebar from './PageWithSidebar'

class Routes extends Component {
    render() {
        return (
            <div>
                <Route exact path="/users/profile/" component={Profile} />
                <Route exact path="/users/admin/" component={UsersManagemnet} />
                <Route
                    exact
                    path="/users/admin/trackers/"
                    component={Trackers}
                />
                <Route exact path="/page-with/" component={PageWithSidebar} />
            </div>
        )
    }
}

export default Routes
