import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import './assets/scss/Common.scss'
import './App.css'

import Root from './containers/Root'
import 'react-notifications/src/notifications.scss'

// GraphQL imports
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from 'react-apollo'

const token = localStorage.getItem('token')

const client = new ApolloClient({
    uri: '/api/graphql',
    headers: {
        Authorization: token ? `Token ${token}` : 'notok',
    },
    onError: errorObj => {
        if (errorObj && errorObj.response && errorObj.response.status === 401) {
            client.resetStore()
            window.location = '/'
        }
        if (errorObj && errorObj.response && errorObj.response.status === 403) {
            window.location = '/'
        }
    },
})

class App extends Component {
    render() {
        return (
            <ApolloProvider client={client}>
                <Router>
                    <Root />
                </Router>
            </ApolloProvider>
        )
    }
}

export default App
