import React, { Component } from 'react'
import InputField from '../../../project-pages/common/InputField'
import PropTypes from 'prop-types'
import Buttons from '../general/Buttons'
import { Mutation } from 'react-apollo'
import {
    GET_TRACKERS,
    UPDATE_TRACKER,
} from '../../../../containers/queries/TrackerQueries'
import { GET_USER } from '../../../../containers/queries/UsersQueries'
class EditTracker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tracker: {
                name: this.props.name,
                url: this.props.url,
                type: this.props.type,
            },
            types: ['Redmine', 'Jira'],
        }

        this.handleTrackerChange = this.handleTrackerChange.bind(this)
        this.handleTypeChange = this.handleTypeChange.bind(this)
        this.handleTypeChange = this.handleTypeChange.bind(this)
    }

    handleTrackerChange(key, value) {
        let tracker = this.state.tracker
        tracker[key] = value
        this.setState(tracker)
    }

    handleTypeChange(event) {
        let tracker = this.state.tracker
        tracker.type = event.target.value
        this.setState({ tracker: tracker })
    }
    checkValidity() {
        var tracker = this.state.tracker.url
        if (tracker[tracker.length - 1] !== '/') {
            tracker = tracker + '/'
            this.setState({ tracker: tracker })
            return tracker
        } else return tracker
    }
    render() {
        return (
            <tr className="table-info">
                <th scope="row">
                    <div className="form-group">
                        <InputField
                            placeholder="Name"
                            defaultValue={this.props.name}
                            id="name"
                            onChange={this.handleTrackerChange}
                        />
                    </div>
                </th>
                <td>
                    <div className="form-group">
                        <InputField
                            placeholder="Url"
                            defaultValue={this.props.url}
                            id="url"
                            onChange={this.handleTrackerChange}
                        />
                    </div>
                </td>
                <td>
                    <div className="form-group">
                        <select
                            className="form-control"
                            id="endPoints"
                            onChange={this.handleTypeChange}
                            value={this.state.tracker.type}
                        >
                            {this.state.types.map(type => (
                                <option id={type} key={type}>
                                    {' '}
                                    {type}{' '}
                                </option>
                            ))}
                        </select>
                    </div>
                </td>
                <td className="text-right">
                    <Mutation
                        mutation={UPDATE_TRACKER}
                        refetchQueries={() => {
                            return [
                                { query: GET_TRACKERS },
                                {
                                    query: GET_USER,
                                    variables: { _id: localStorage.id },
                                },
                            ]
                        }}
                        onCompleted={() => {
                            this.props.handlers.activeNotifier(
                                'success',
                                'Tracker is edited successfully.'
                            )
                        }}
                        onError={() => {
                            this.props.handlers.activeNotifier(
                                'error',
                                'Failure in editing the tracker.'
                            )
                        }}
                    >
                        {updateTracker => {
                            const handlers = {
                                submit: () => {
                                    updateTracker({
                                        variables: {
                                            _id: this.props._id,
                                            name: this.state.tracker.name,
                                            url: this.checkValidity(),
                                            type: this.state.tracker.type,
                                        },
                                    })
                                },
                                cancel: this.props.handlers.handleCancelEditing,
                            }
                            const submitButton = {
                                id: 'save_tracker',
                                buttonClass: 'btn btn-info mr-5 btn-small',
                                icon: 'fas fa-check-circle',
                                textClass: 'd-none d-lg-inline ml-5',
                                title: 'Save',
                            }
                            return (
                                <Buttons
                                    submitButton={submitButton}
                                    handlers={handlers}
                                />
                            )
                        }}
                    </Mutation>
                </td>
            </tr>
        )
    }
}
EditTracker.propTypes = {
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    handlers: PropTypes.object.isRequired,
}
export default EditTracker
