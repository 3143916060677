function checkLength(value) {
    if (value.length === 1) return '0' + value
    else return value
}
export function dateParser(seconds) {
    var t = new Date(parseInt(seconds) * 1000)
    var date = ''
    date = checkLength(t.getDate().toString())
    date += '/' + checkLength((t.getMonth() + 1).toString())
    date += '/' + t.getFullYear()
    date += '  '
    date += checkLength(t.getHours().toString())
    date += ':' + checkLength(t.getMinutes().toString())
    date += ':' + checkLength(t.getSeconds().toString())
    return date
}

export function GetDirectory() {
    if (window.location.pathname.indexOf('trackers') !== -1) return 'Trackers'
    else if (window.location.pathname.indexOf('profile') !== -1)
        return 'Profile'
    else if (window.location.pathname.indexOf('admin') !== -1) return 'Admin'
}
