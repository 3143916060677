import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Mutation } from 'react-apollo'
import { CHANGE_PASSWORD } from '../../../../containers/queries/UsersQueries'

class ChangePassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            oldPassword: '',
            newPassword: '',
            confirmation: '',
            aquiredLength: null,
            equal: null,
            containsSpace: false,
        }
        this.handleChange = this.handleChange.bind(this)
        this.isCharachterMatched = this.isCharachterMatched.bind(this)
        this.isEmpty = this.isEmpty.bind(this)
        this.passwordChanged = this.passwordChanged.bind(this)
        this.renderMsg = this.renderMsg.bind(this)
        this.checkLength = this.checkLength.bind(this)
    }
    handleChange(event) {
        event.preventDefault()
        this.setState({
            [event.target.id]: event.target.value,
            equal: null,
        })
        this.props.handlers.deActiveNotifier()
        event.target.value.indexOf(' ') == -1
            ? this.setState({ containsSpace: false })
            : this.setState({ containsSpace: true })
        if (
            event.target.id === 'confirmation' &&
            this.state.newPassword !== '' &&
            this.isCharachterMatched(event.target.value)
        )
            this.setState({ equal: false })
    }
    isCharachterMatched(value) {
        return (
            this.state.newPassword[this.state.confirmation.length] !==
            value[value.length - 1]
        )
    }

    isEmpty(element) {
        return element.length === 0 ? true : false
    }

    checkLength() {
        this.state.newPassword.length < 6 || this.state.newPassword.length > 128
            ? this.setState({ aquiredLength: false })
            : this.setState({ aquiredLength: true })
    }

    checkValidity() {
        if (this.isEmpty(this.state.oldPassword)) return false
        if (this.isEmpty(this.state.newPassword)) return false
        if (
            this.isEmpty(this.state.confirmation) ||
            this.state.newPassword !== this.state.confirmation
        )
            return false
        if (!this.state.aquiredLength) return false
        if (this.state.containsSpace) return false
        if (this.state.equal === false) return false
        else return true
    }

    showMsg(msg) {
        return (
            <span className="col-md-4" style={{ color: 'red' }}>
                <small>{msg}</small>
            </span>
        )
    }
    renderMsg() {
        if (this.state.containsSpace)
            return this.showMsg('Empty spaces are not accepted.')
        else if (this.state.equal === false)
            return this.showMsg(
                'New password and Confirm new password do not match.'
            )
        else if (
            this.state.aquiredLength === false &&
            !this.isEmpty(this.state.newPassword)
        )
            return this.showMsg('Password must be at least 6 characters.')
        else return <div />
    }

    passwordChanged(response, message) {
        this.setState({
            oldPassword: '',
            newPassword: '',
            confirmation: '',
        })
        this.props.handlers.activeNotifier(response, message)
    }

    render() {
        return (
            <div id="change_password">
                <div className="card mb-30">
                    <div className="card-header">
                        <div className="row">
                            <div className="col">
                                <h2 className="card-header__title">
                                    Change Password
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <form
                            className="row"
                            onSubmit={e => e.preventDefault()}
                        >
                            <div className="form-group col-md-4">
                                <label>Old password</label>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="oldPassword"
                                        value={this.state.oldPassword}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group col-md-4">
                                <label>New password</label>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="newPassword"
                                        value={this.state.newPassword}
                                        onChange={this.handleChange}
                                        onBlur={this.checkLength}
                                        onKeyUp={this.isCharachterMatched}
                                    />
                                </div>
                            </div>
                            <div className="form-group col-md-4">
                                <label>Confirm new password</label>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="confirmation"
                                        value={this.state.confirmation}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col text-right">
                                <Mutation
                                    mutation={CHANGE_PASSWORD}
                                    variables={{
                                        _id: this.props.user._id,
                                        old_password: this.state.oldPassword,
                                        new_password: this.state.newPassword,
                                    }}
                                    onCompleted={() => {
                                        this.passwordChanged(
                                            'success',
                                            'Password is changed successfully.'
                                        )
                                    }}
                                    onError={() => {
                                        this.passwordChanged(
                                            'error',
                                            'Failure in changing password.'
                                        )
                                    }}
                                >
                                    {changePassword => {
                                        return (
                                            <button
                                                className={
                                                    this.checkValidity()
                                                        ? 'btn btn-success mr-10'
                                                        : 'btn btn-success is-disabled mr-10'
                                                }
                                                id="change_pass_btn"
                                                onClick={changePassword}
                                            >
                                                <i className="fas fa-check-circle" />{' '}
                                                Change Password
                                            </button>
                                        )
                                    }}
                                </Mutation>
                            </div>
                        </div>
                        {this.renderMsg()}
                    </div>
                </div>
            </div>
        )
    }
}
ChangePassword.propTypes = {
    user: PropTypes.object,
    handlers: PropTypes.object,
}
export default ChangePassword
