import React, { Component } from 'react'
//import PageTitle from '../components/menu/user-menu/general/PageTitle'
import ChangePassword from '../components/menu/user-menu/profile/ChangePassword'
import ProfileTable from '../components/menu/user-menu/profile/ProfileTable'
import { Query } from 'react-apollo'
import { GET_USER } from './queries/UsersQueries'
import Notifier from '../components/project-pages/common/Notifier'

class Profile extends Component {
    constructor(props) {
        super(props)

        this.state = {
            notifierTitle: '',
            notifierMsg: '',
        }
        this.activeNotifier = this.activeNotifier.bind(this)
        this.deActiveNotifier = this.deActiveNotifier.bind(this)
    }
    activeNotifier(response, message) {
        this.setState({ notifierTitle: response, notifierMsg: message })
    }
    deActiveNotifier() {
        this.setState({ notifierTitle: '', notifierMsg: '' })
    }

    render() {
        const id = localStorage.id
        const handlers = {
            activeNotifier: this.activeNotifier,
            deActiveNotifier: this.deActiveNotifier,
        }
        return (
            <Query
                query={GET_USER}
                variables={{ _id: id }}
                notifyOnNetworkStatusChange
            >
                {({ loading, data }) => {
                    if (loading) return <span />
                    return (
                        <div className="l__full-page">
                            <div className="container-fluid">
                                <div id="head-page" className="row">
                                    <div className="col-sm-6 head-page__title">
                                        <h1 className="head-page__title-title">
                                            <i className="fas fa-user head-page__title-ico" />{' '}
                                            Profile
                                        </h1>
                                    </div>
                                </div>
                                <Notifier
                                    title={this.state.notifierTitle}
                                    message={this.state.notifierMsg}
                                    type={this.state.notifierTitle}
                                />
                                <ChangePassword
                                    user={data.user}
                                    handlers={handlers}
                                />
                                <ProfileTable
                                    user={data.user}
                                    handlers={handlers}
                                />
                            </div>
                        </div>
                    )
                }}
            </Query>
        )
    }
}

export default Profile
