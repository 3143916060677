import React, { Component } from 'react'
import RenderUser from './RenderUser.js'
import PropTypes from 'prop-types'

import NewUser from './NewUser'

const TableHeader = (index, header) => {
    return (
        <th key={index} scope="col" className={header.className}>
            <i className={header.iclass} /> {header.title}
        </th>
    )
}
class UsersTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            headers: [
                {
                    title: 'Date',
                    iclass: 'ico-prefix fas fa-calendar-day',
                    className: '',
                },
                { title: 'Name', iclass: 'fas fa-user', className: '' },
                {
                    title: 'User name',
                    iclass: 'fas fa-user-tag',
                    className: '',
                },
                { title: 'Email', iclass: 'fas fa-envelope', className: '' },
                {
                    title: 'Password',
                    iclass: 'fas fa-user-shield',
                    className: '',
                },
                { title: 'Admin', iclass: 'fas fa-star', className: '' },
                {
                    title: 'Option',
                    iclass: 'fas fa-cog',
                    className: 'text-right',
                },
            ],
        }
    }

    render() {
        return (
            <div className="card">
                <div className="card-body p-0">
                    <div className="table-responsive">
                        <form
                            onSubmit={e => {
                                e.preventDefault()
                            }}
                        >
                            <table className="table table-hover table-middle">
                                <thead>
                                    <tr>
                                        {this.state.headers.map(
                                            (header, index) =>
                                                TableHeader(index, header)
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.newUser && (
                                        <NewUser
                                            handlers={this.props.handlers}
                                        />
                                    )}
                                    {this.props.users.map((user, index) => {
                                        return (
                                            <RenderUser
                                                _id={user._id}
                                                creationDate={user.creation}
                                                key={user._id}
                                                index={index}
                                                name={user.name}
                                                username={user.username}
                                                email={user.email || ''}
                                                role={user.role}
                                                deleteUser={
                                                    this.props.deleteUser ==
                                                    index
                                                        ? true
                                                        : false
                                                }
                                                enableEdit={
                                                    this.props.editUser == index
                                                        ? true
                                                        : false
                                                }
                                                handlers={this.props.handlers}
                                            />
                                        )
                                    })}
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
UsersTable.propTypes = {
    users: PropTypes.array.isRequired,
    newUser: PropTypes.bool.isRequired,
    handlers: PropTypes.object.isRequired,
    editUser: PropTypes.number.isRequired,
    deleteUser: PropTypes.number.isRequired,
}
export default UsersTable
