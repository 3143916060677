import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

class UserMenu extends Component {
    constructor(props) {
        super(props)
        if (this.props.user.role !== '' && this.props.user.role === 'admin') {
            this.state = {
                menuElements: [
                    {
                        text: 'Profile',
                        icon: 'dropdown-icon fas fa-user',
                        target: '/users/profile/',
                        id: 'profile',
                    },
                    {
                        text: 'Users management',
                        icon: 'dropdown-icon fas fa-users-cog',
                        target: '/users/admin/',
                        id: 'admin',
                    },
                    {
                        text: 'Trackers management',
                        icon: 'dropdown-icon fas fa-file-alt',
                        target: '/users/admin/trackers/',
                        id: 'trackers',
                    },
                ],
            }
        } else {
            this.state = {
                menuElements: [
                    {
                        text: 'Profile',
                        icon: 'dropdown-icon fas fa-user',
                        target: '/users/profile/',
                        id: 'profile',
                    },
                ],
            }
        }
    }
    clearLocalStorage() {
        localStorage.removeItem('token')
        localStorage.removeItem('id')
    }

    render() {
        return (
            <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="navbarDropdown-admin"
            >
                {this.state.menuElements.map((el, index) => {
                    return (
                        <Link
                            onClick={() => this.props.handler()}
                            className="dropdown-item"
                            to={el.target}
                            key={index}
                            id={el.id}
                        >
                            <i className={el.icon} /> {el.text}
                        </Link>
                    )
                })}
                <div className="dropdown-divider" />
                <a
                    className="dropdown-item dropdown-logout"
                    onClick={() => this.clearLocalStorage()}
                    href="/"
                >
                    <i className="dropdown-icon fas fa-sign-out-alt" /> Log out
                </a>
            </div>
        )
    }
}
UserMenu.propTypes = {
    user: PropTypes.object,
    handler: PropTypes.func,
}
export default UserMenu
