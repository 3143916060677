import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Mutation } from 'react-apollo'
import Buttons from '../general/Buttons'
import {
    GET_USER,
    UPDATE_TRACKER_KEY,
} from '../../../../containers/queries/UsersQueries'
import { GET_TRACKERS } from '../../../../containers/queries/TrackerQueries'

class ProfileTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableTitle: [
                { title: 'Name', className: 'fas fa-file-signature' },
                { title: 'Url', className: 'fas fa-link' },
                { title: 'Type', className: 'fas fa-link' },
                { title: 'Key', className: 'fas fa-lock' },
                { title: 'Your Jira username', className: 'fas fa-user' },
            ],
            edit: -1,
            delete: -1,
            key: '',
            tracekrUserName: null,
        }
        this.activeEdit = this.activeEdit.bind(this)
        this.cancelEditing = this.cancelEditing.bind(this)
        this.renderTracker = this.renderTracker.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.trackerEdited = this.trackerEdited.bind(this)
        this.isDisabeld = this.isDisabeld.bind(this)
    }
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    activeEdit(e, index) {
        e.preventDefault()
        this.setState({
            edit: index,
            tracekrUserName: null,
            delete: -1,
        })
        this.props.handlers.deActiveNotifier()
    }
    activeDelete(e, index) {
        e.preventDefault()
        this.setState({
            delete: index,
            tracekrUserName: null,
            edit: -1,
        })
        this.props.handlers.deActiveNotifier()
    }
    cancelEditing() {
        this.setState({
            edit: -1,
            delete: -1,
            tracekrUserName: null,
        })
        this.props.handlers.deActiveNotifier()
    }

    isDisabeld(tr) {
        if (
            tr.tracker_type === 'Jira' &&
            (this.state.tracekrUserName === null ||
                this.state.tracekrUserName === '')
        )
            return true
        else if (tr.tracker_type !== 'Jira' && this.state.key === '')
            return true
        else return false
    }
    userHasApiAuthorization(tr, value) {
        var currentUserTracker = this.props.user.trackers.find(
            tracker => tracker.tracker_id === tr.tracker_id
        )
        return currentUserTracker[value]
    }

    renderTableHeader(title, className) {
        return (
            <th scope="col" key={title}>
                <i className={className} /> {title}
            </th>
        )
    }
    renderTracker(tr, index) {
        return (
            <tr key={index}>
                <th scope="row" id={`name-${index}`}>
                    <i className="table-ico-prefix fas fa-file-signature" />{' '}
                    {tr.tracker_name}
                </th>
                <td id={`url-${index}`}>
                    <i className="table-ico-prefix fas fa-link" />{' '}
                    {tr.tracker_url}
                </td>
                <td id={`url-${tr.type}`}>
                    <i className="table-ico-prefix fas fa-signature" />{' '}
                    {tr.tracker_type}
                </td>
                <td id={`key-${tr.url}`}>
                    {this.userHasApiAuthorization(tr, 'tracker_key') === true ||
                    this.userHasApiAuthorization(tr, 'tracker_key') === 'true'
                        ? '***'
                        : '---'}
                </td>
                <td id={`key-${'username' - index}`}>
                    {this.userHasApiAuthorization(tr, 'tracker_username') ===
                        true ||
                    this.userHasApiAuthorization(tr, 'tracker_username') ===
                        'true'
                        ? '***'
                        : '---'}
                </td>
                <td className="text-right">
                    <a
                        href=""
                        id={`edit-${index}`}
                        onClick={e => this.activeEdit(e, index)}
                        className="text-info mr-10"
                    >
                        <i className="fas fa-edit hover-pulse" />
                    </a>
                    <a
                        href=""
                        id={`delete-${index}`}
                        className="text-danger"
                        onClick={e => this.activeDelete(e, index)}
                    >
                        <i className="fas fa-times-circle hover-pulse" />
                    </a>
                </td>
            </tr>
        )
    }

    trackerEdited(response, message) {
        this.props.handlers.activeNotifier(response, message)
        this.setState({
            edit: -1,
            delete: -1,
        })
    }

    editTracker(tr, index) {
        return (
            <tr key={index}>
                <th scope="row" id={`name-${index}`}>
                    <i className="table-ico-prefix fas fa-file-signature" />{' '}
                    {tr.tracker_name}
                </th>
                <td id={`url-${index}`}>
                    <i className="table-ico-prefix fas fa-link" />{' '}
                    {tr.tracker_url}
                </td>
                <td id={`type-${index}`}>
                    <i className="table-ico-prefix fas fa-signature" />{' '}
                    {tr.tracker_type}
                </td>
                <td id={`tracker_username-${index}`}>
                    <input
                        type="text"
                        id="edit"
                        name="key"
                        placeholder=""
                        onChange={this.handleChange}
                        defaultValue={
                            this.userHasApiAuthorization(tr, 'tracker_key') ===
                                true ||
                            this.userHasApiAuthorization(tr, 'tracker_key') ===
                                'true'
                                ? '***'
                                : '---'
                        }
                    />
                </td>
                <td>
                    <div className="form-group">
                        {tr.tracker_type === 'Jira' ? (
                            <input
                                type="text"
                                id="tracker_username"
                                name="tracekrUserName"
                                placeholder=""
                                disabled={false}
                                onChange={this.handleChange}
                                onKeyUp={this.handleChange}
                                defaultValue={
                                    this.userHasApiAuthorization(
                                        tr,
                                        'tracker_username'
                                    ) === true ||
                                    this.userHasApiAuthorization(
                                        tr,
                                        'tracker_username'
                                    ) === 'true'
                                        ? '***'
                                        : '---'
                                }
                            />
                        ) : (
                            '---'
                        )}
                    </div>
                </td>
                <td className="text-right">
                    <Mutation
                        mutation={UPDATE_TRACKER_KEY}
                        refetchQueries={() => {
                            return [
                                { query: GET_TRACKERS },
                                {
                                    query: GET_USER,
                                    variables: { _id: localStorage.id },
                                },
                            ]
                        }}
                        onCompleted={() => {
                            this.trackerEdited(
                                'success',
                                'Tracker key Updated.'
                            )
                        }}
                        onError={() => {
                            this.trackerEdited('error', 'Failure in updating.')
                        }}
                    >
                        {updateTrackerKey => {
                            const handlers = {
                                submit: () => {
                                    if (!this.isDisabeld(tr)) {
                                        updateTrackerKey({
                                            variables: {
                                                _id: this.props.user._id,
                                                tracker_id: tr.tracker_id,
                                                tracker_key: this.state.key,
                                                tracker_username: this.state
                                                    .tracekrUserName,
                                            },
                                        })
                                    }
                                },
                                cancel: () => this.cancelEditing(),
                            }
                            const submitButton = {
                                id: 'save_key',
                                buttonClass: this.isDisabeld(tr)
                                    ? 'btn btn-info mr-5 is-disabled btn-small'
                                    : 'btn btn-info mr-5 btn-small',
                                icon: 'fas fa-check-circle',
                                textClass: 'd-none d-lg-inline ml-5',
                                title: 'Save',
                            }
                            return (
                                <Buttons
                                    submitButton={submitButton}
                                    handlers={handlers}
                                />
                            )
                        }}
                    </Mutation>
                </td>
            </tr>
        )
    }

    deleteTracker(tr, index) {
        return (
            <tr className="table-danger">
                <td id={`name-${index}`}>
                    <i className="table-ico-prefix fas fa-file-signature" />{' '}
                    {tr.tracker_name}
                </td>
                <td id={`url-${index}`}>
                    <i className="table-ico-prefix fas fa-link" />{' '}
                    {tr.tracker_url}
                </td>
                <td id={`type-${tr.type}`}>
                    <i className="table-ico-prefix fas fa-signature" />{' '}
                    {tr.tracker_type}
                </td>
                <td id={`key-${tr.url}`}>
                    {this.userHasApiAuthorization(tr, 'tracker_key') === true ||
                    this.userHasApiAuthorization(tr, 'tracker_key') === 'true'
                        ? '***'
                        : '---'}
                </td>
                <td>
                    {this.userHasApiAuthorization(tr, 'tracker_username') ===
                        true ||
                    this.userHasApiAuthorization(tr, 'tracker_username') ===
                        'true'
                        ? '***'
                        : '---'}
                </td>
                <td className="text-right">
                    <Mutation
                        mutation={UPDATE_TRACKER_KEY}
                        refetchQueries={() => {
                            return [
                                { query: GET_TRACKERS },
                                {
                                    query: GET_USER,
                                    variables: { _id: localStorage.id },
                                },
                            ]
                        }}
                        onCompleted={() => {
                            this.trackerEdited(
                                'success',
                                'Tracker key is removed'
                            )
                        }}
                        onError={() => {
                            this.trackerEdited('error', 'Failure in Removing.')
                        }}
                    >
                        {updateTrackerKey => {
                            const handlers = {
                                submit: () => {
                                    updateTrackerKey({
                                        variables: {
                                            tracker_id: tr.tracker_id,
                                            _id: this.props.user._id,
                                            tracker_key: null,
                                        },
                                    })
                                },
                                cancel: () => this.cancelEditing(),
                            }
                            const submitButton = {
                                id: 'submit-delete',
                                buttonClass: 'btn btn-danger mr-5 btn-small',
                                icon: 'fas fa-check-circle',
                                textClass: 'd-none d-lg-inline ml-5',
                                title: 'Delete',
                            }
                            return (
                                <Buttons
                                    submitButton={submitButton}
                                    handlers={handlers}
                                />
                            )
                        }}
                    </Mutation>
                </td>
            </tr>
        )
    }

    render() {
        return (
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col">
                            <h2 className="card-header__title">Trackers</h2>
                        </div>
                    </div>
                </div>
                <div className="card-body p-0">
                    <form className="table-responsive">
                        <table className="table table-hover table-middle">
                            <thead>
                                <tr>
                                    {this.state.tableTitle.map(el =>
                                        this.renderTableHeader(
                                            el.title,
                                            el.className
                                        )
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.user.trackers.map((tr, index) => {
                                    if (index == this.state.edit)
                                        return this.editTracker(tr, index)
                                    else if (index == this.state.delete)
                                        return this.deleteTracker(tr, index)
                                    else return this.renderTracker(tr, index)
                                })}
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        )
    }
}
ProfileTable.propTypes = {
    user: PropTypes.object,
    handlers: PropTypes.object,
}
export default ProfileTable
