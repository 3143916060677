import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import PropTypes from 'prop-types'
import Buttons from '../general/Buttons'

import {
    GET_USERS,
    ADD_USER,
} from '../../../../containers/queries/UsersQueries'
class NewTracker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            username: '',
            email: '',
            role: '',
            notifierTitle: '',
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this)
    }

    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value })
    }
    handleChangeCheckBox(event) {
        this.setState({ role: event.target.checked ? 'admin' : 'user' })
    }
    render() {
        return (
            <tr className="table-success">
                <td />
                <td>
                    <input
                        type="text"
                        placeholder="Name"
                        id="name"
                        onChange={this.handleChange}
                    />
                </td>
                <td>
                    <input
                        type="text"
                        placeholder="Username"
                        id="username"
                        onChange={this.handleChange}
                    />
                </td>
                <td>
                    <input
                        type="text"
                        placeholder="Email"
                        id="email"
                        onChange={this.handleChange}
                    />
                </td>
                <td>
                    <i className="fas fa-lock" />
                </td>
                <td>
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            checked={this.state.role === 'admin' ? true : false}
                            className="custom-control-input"
                            id="customCheck1"
                            onChange={this.handleChangeCheckBox}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="customCheck1"
                        >
                            Admin
                        </label>
                    </div>
                </td>
                <td className="text-right">
                    <Mutation
                        mutation={ADD_USER}
                        refetchQueries={() => {
                            return [{ query: GET_USERS }]
                        }}
                        onCompleted={() => {
                            this.props.handlers.activeNotifier(
                                'success',
                                'User is added successfully.'
                            )
                        }}
                        onError={() => {
                            this.props.handlers.activeNotifier(
                                'error',
                                'Failure in adding a user.'
                            )
                        }}
                    >
                        {addUser => {
                            const handlers = {
                                submit: () => {
                                    if (
                                        this.state.name !== '' &&
                                        this.state.url !== '' &&
                                        this.state.email !== ''
                                    ) {
                                        addUser({
                                            variables: {
                                                name: this.state.name,
                                                username: this.state.username,
                                                email: this.state.email,
                                                role: this.state.role,
                                            },
                                        })
                                    }
                                },
                                cancel: this.props.handlers.cancelCreation,
                            }
                            const submitButton = {
                                id: 'create_user',
                                buttonClass:
                                    this.state.name !== '' &&
                                    this.state.username !== '' &&
                                    this.state.email !== ''
                                        ? 'btn btn-success mr-5 btn-small'
                                        : 'btn btn-success is-disabled mr-5 btn-small',
                                icon: 'fas fa-check-circle',
                                textClass: 'd-none d-lg-inline ml-5',
                                title: 'Create',
                            }
                            return (
                                <Buttons
                                    submitButton={submitButton}
                                    handlers={handlers}
                                />
                            )
                        }}
                    </Mutation>
                </td>
            </tr>
        )
    }
}
NewTracker.propTypes = {
    handlers: PropTypes.object.isRequired,
}
export default NewTracker
