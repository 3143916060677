import React from 'react'
import PropTypes from 'prop-types'
const Buttons = props => {
    return (
        <span>
            <button
                id={props.submitButton.id}
                className={props.submitButton.buttonClass}
                onClick={e => {
                    e.preventDefault()
                    props.handlers.submit()
                }}
            >
                <i className={props.submitButton.icon} />
                <span className={props.submitButton.textClass}>
                    {props.submitButton.title}
                </span>
            </button>
            <button
                id="cancel"
                className="btn btn-muted btn-small"
                onClick={e => {
                    e.preventDefault()
                    props.handlers.cancel()
                }}
            >
                <i className="fas fa-times-circle" />
                <span className="d-none d-lg-inline ml-5">Cancel</span>
            </button>
        </span>
    )
}

Buttons.propTypes = {
    submitButton: PropTypes.object.isRequired,
    handlers: PropTypes.object.isRequired,
}

export default Buttons
