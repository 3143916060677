import React, { Component } from 'react'
import RenderTracker from './RenderTracker.js'
import PropTypes from 'prop-types'

import NewTracker from './NewTracker'

const TableHeader = (index, header) => {
    return (
        <th key={index} scope="col" className={header.className}>
            <i className={header.iclass} /> {header.title}
        </th>
    )
}
class TrackerTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            headers: [
                {
                    title: 'Name',
                    iclass: 'fas fa-file-signature',
                    className: '',
                },
                { title: 'Url', iclass: 'fas fa-link', className: '' },
                { title: 'Type', iclass: 'fas fa-signature', className: '' },
                {
                    title: 'Option',
                    iclass: 'fas fa-cog',
                    className: 'text-right',
                },
            ],
        }
    }

    render() {
        return (
            <div className="card">
                <div className="card-body p-0">
                    <div className="table-responsive">
                        <form
                            onSubmit={e => {
                                e.preventDefault()
                            }}
                        >
                            <table className="table table-hover table-middle">
                                <thead>
                                    <tr>
                                        {this.state.headers.map(
                                            (header, index) =>
                                                TableHeader(index, header)
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.newTracker && (
                                        <NewTracker
                                            handlers={this.props.handlers}
                                        />
                                    )}
                                    {this.props.trackers.length > 0 ? (
                                        this.props.trackers.map(
                                            (tracker, index) => {
                                                return (
                                                    <RenderTracker
                                                        _id={tracker._id}
                                                        key={tracker._id}
                                                        index={index}
                                                        name={tracker.name}
                                                        url={tracker.url}
                                                        deleteTracker={
                                                            this.props
                                                                .deleteTracker ==
                                                            index
                                                                ? true
                                                                : false
                                                        }
                                                        enableEdit={
                                                            this.props
                                                                .editTracker ==
                                                            index
                                                                ? true
                                                                : false
                                                        }
                                                        type={
                                                            tracker.type || ''
                                                        }
                                                        handlers={
                                                            this.props.handlers
                                                        }
                                                    />
                                                )
                                            }
                                        )
                                    ) : (
                                        <tr key="no_tracker">
                                            <th scope="row" id="no_tracker">
                                                <i className="table-ico-prefix fas fa-file-signature" />{' '}
                                                No tracker to show
                                            </th>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
TrackerTable.propTypes = {
    trackers: PropTypes.array.isRequired,
    newTracker: PropTypes.bool.isRequired,
    handlers: PropTypes.object.isRequired,
    editTracker: PropTypes.number.isRequired,
    deleteTracker: PropTypes.number.isRequired,
}
export default TrackerTable
