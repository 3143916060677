import React, { Component } from 'react'
import Sidebar from './Sidebar'

class PageWithSidebar extends Component {
    render() {
        return (
            <div className="l__full-page l__full-page--sidebarSmall">
                <Sidebar />

                <div className="container-fluid container-sidebar" />
            </div>
        )
    }
}

export default PageWithSidebar
