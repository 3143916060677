import gql from 'graphql-tag'

const GET_TRACKERS = gql`
    query {
        trackers {
            _id
            name
            url
            type
        }
    }
`

const ADD_TRACKER = gql`
    mutation AddTracker($url: String!, $name: String!, $type: String!) {
        add_tracker(url: $url, name: $name, type: $type) {
            _id
            url
            name
            type
        }
    }
`

const UPDATE_TRACKER = gql`
    mutation UpdateTracker(
        $_id: String!
        $url: String!
        $name: String!
        $type: String!
    ) {
        update_tracker(_id: $_id, url: $url, name: $name, type: $type) {
            _id
            url
            name
            type
        }
    }
`

const REMOVE_TRACKER = gql`
    mutation RemoveTracker($_id: String!) {
        remove_tracker(_id: $_id) {
            _id
            name
            url
            type
        }
    }
`

export { GET_TRACKERS, ADD_TRACKER, UPDATE_TRACKER, REMOVE_TRACKER }
